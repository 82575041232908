import React from "react";
import { Carousel } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";

const ImageCarousel = ({ images, height, activeslide, borderRadius }) => {
	return (
		<Carousel
			fade={true}
			touch={false}
			controls={false}
			activeIndex={activeslide}
			indicators={false}
		>
			{images.map((image, index) => (
				<Carousel.Item key={index}>
					<GatsbyImage
						className={`mb-3 ${borderRadius ? 'rounded-2' : ''}`}
						style={{
							height: height,
							//   maxHeight: { height },
						}}
						image={image.localFile.childImageSharp.gatsbyImageData}
						alt={image.altText}
					/>
				</Carousel.Item>
			))}
		</Carousel>
	);
};

export default ImageCarousel;
